import React from "react";
import { Link } from "gatsby";
import shape9 from "../../assets/images/shape/vector-shape9.png";
import shape10 from "../../assets/images/shape/vector-shape10.png";
import { StaticImage } from "gatsby-plugin-image";

const StartProject = () => {
  return (
    <div className="project-start-area bg-color ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <StaticImage placeholder="blurred" src="../../assets/images/setup-staking.png" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>Get Started with Staking</h2>
              <p>Get started by walking through our in-depth documentations and guides on how to get started with staking by becoming a node on the Hydra network.</p>

              <Link to="/contact" className="default-btn">
                <i className="flaticon-web"></i>
                Get Started
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape9">
        <img src={shape9} alt="about" />
      </div>
      <div className="vector-shape10">
        <img src={shape10} alt="about" />
      </div>
    </div>
  );
};

export default StartProject;
